import constants from '../Utils/constants';
const initialState = {
  isFeaturesFlagLoading: false,
  isCreateFeaturesFlagLoading: false,
  isGetFeaturesFlagByIdLoading: false,
  isUpdateFeaturesFlagLoading: false,
  allFeaturesFlag: [],
  FeaturesFlag: {},
};

export const featuresFlagReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_FEATURES_FLAG_REQUEST:
      return {
        ...state,
        isFeaturesFlagLoading: true,
      };
    case constants.GET_FEATURES_FLAG_SUCCESS:
      return {
        ...state,
        isFeaturesFlagLoading: false,
        allFeaturesFlag: action.payload,
      };
    case constants.GET_FEATURES_FLAG_FAILURE:
      return {
        ...state,
        isFeaturesFlagLoading: false,
      };
    case constants.CREATE_FEATURES_FLAG_REQUEST:
      return {
        ...state,
        isCreateFeaturesFlagLoading: true,
      };
    case constants.CREATE_FEATURES_FLAG_SUCCESS:
      return {
        ...state,
        isCreateFeaturesFlagLoading: false,
      };
    case constants.CREATE_FEATURES_FLAG_FAILURE:
      return {
        ...state,
        isCreateFeaturesFlagLoading: false,
      };
    case constants.UPDATE_FEATURES_FLAG_REQUEST:
      return {
        ...state,
        isUpdateFeaturesFlagLoading: true,
      };
    case constants.UPDATE_FEATURES_FLAG_SUCCESS:
      return {
        ...state,
        isUpdateFeaturesFlagLoading: false,
      };
    case constants.UPDATE_FEATURES_FLAG_FAILURE:
      return {
        ...state,
        isUpdateFeaturesFlagLoading: false,
      };
    case constants.GET_FEATURES_FLAG_BY_ID_REQUEST:
      return {
        ...state,
        isGetFeaturesFlagByIdLoading: true,
      };
    case constants.GET_FEATURES_FLAG_BY_ID_SUCCESS:
      return {
        ...state,
        isGetFeaturesFlagByIdLoading: false,
        FeaturesFlag: action.payload,
      };
    case constants.GET_FEATURES_FLAG_BY_ID_FAILURE:
      return {
        ...state,
        isGetFeaturesFlagByIdLoading: false,
      };
    default:
      return state;
  }
};
