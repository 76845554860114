import React, { useEffect, useState } from 'react';
import './Restaurant.css';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  message,
  Card,
  Checkbox,
  Switch,
} from 'antd';
import {
  updateCapabilitiesRestaurantAction,
  getRestaurantDetails,
  getRestaurant,
} from '../../Actions/RestaurantAction';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
const Capabilities = (props) => {
  const {
    editId,
    setEditId,
    setIsDefaultImageVisible,
    setShowUploadImage,
    setTabkey,
    currentPage,
  } = props;
  const dispatch = useDispatch();
  const [newFiles, setNewFiles] = useState();
  const IsEmpty = _.isEmpty(newFiles) ? true : false;
  const [preloadedRestaurantValue, setPreloadedRestaurantValue] = useState('');

  const {
    allRestaurant,
    isCreateRestaurantLoading,
    isUpdateRestaurantLoading,
  } = useSelector((state) => {
    const {
      allRestaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
    } = state.restaurant;
    return {
      allRestaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
    };
  });

  const { organizations } = allRestaurant;
  const the_restaurant = _.filter(organizations, function (o) {
    return o.id === editId;
  });
  const { handleSubmit, control, reset, clearErrors } = useForm({
    defaultValues: {},
  });
  const [isVeg, setisVeg] = useState(
    editId && the_restaurant[0]?.is_veg ? true : false
  );
  const [checkedValueDineIn, setCheckedValueDineIn] = useState(
    editId && the_restaurant[0]?.is_dine_in ? true : false
  );
  const [checkedValueDineInPrePaid, setCheckedValueDineInPrePaid] = useState(
    editId && the_restaurant[0]?.dinein_prepaid ? true : false
  );
  const [checkedValueDineInPostPaid, setCheckedValueDineInPostPaid] = useState(
    editId && the_restaurant[0]?.dinein_pospaid ? true : false
  );
  const [checkedValueTakeAway, setCheckedValueTakeAway] = useState(
    editId && the_restaurant[0]?.is_take_away ? true : false
  );
  const [
    checkedValueTakeAwayOnlinePayment,
    setCheckedValueTakeAwayOnlinePayment,
  ] = useState(
    editId && the_restaurant[0]?.take_away_online_payment ? true : false
  );
  const [checkedValueTakeAwayCashPayment, setCheckedValueTakeAwayCashPayment] =
    useState(
      editId && the_restaurant[0]?.take_away_cash_payment ? true : false
    );
  const [checkedValueDelievery, setCheckedValueDelievery] = useState(
    editId && the_restaurant[0]?.is_delivery ? true : false
  );
  const [checkedValueCashOnDelievery, setCheckedValueCashOnDelievery] =
    useState(
      editId && the_restaurant[0]?.delivery_cash_on_delivery ? true : false
    );
  const [
    checkedValueDelieveryOnlinePayment,
    setCheckedValueDelieveryOnlinePayment,
  ] = useState(
    editId && the_restaurant[0]?.delivery_online_payment ? true : false
  );
  const [isDineInVisible, setIsDineInVisible] = useState(false);

  const handleDineInClick = () => {
    setIsDineInVisible((prev) => !prev);
  };
  const [isTakeAwayVisible, setIsTakeAwayVisible] = useState(false);

  const handleTakeAwayClick = () => {
    setIsTakeAwayVisible((prev) => !prev);
  };
  const [isDelieveryVisible, setIsDelieveryVisible] = useState(false);

  const handleDelieveryClick = () => {
    setIsDelieveryVisible((prev) => !prev);
  };
  const handleisVegMethod = (e) => {
    setisVeg(e);
  };
  const onChangeDineIn = (e) => {
    setCheckedValueDineIn(e);
  };
  const onChangeDineInPrePaid = (e) => {
    setCheckedValueDineInPrePaid(e);
  };
  const onChangeDineInPostPaid = (e) => {
    setCheckedValueDineInPostPaid(e);
  };
  const onChangeTakeaway = (e) => {
    setCheckedValueTakeAway(e);
  };
  const onChangeTakeawayOnlinePayment = (e) => {
    setCheckedValueTakeAwayOnlinePayment(e);
  };
  const onChangeTakeawayCashPayment = (e) => {
    setCheckedValueTakeAwayCashPayment(e);
  };
  const onChangeDelievery = (e) => {
    setCheckedValueDelievery(e);
  };
  const onChangeCashOnDelievery = (e) => {
    setCheckedValueCashOnDelievery(e);
  };
  const onChangeDelieveryOnlinePayment = (e) => {
    setCheckedValueDelieveryOnlinePayment(e);
  };
  const handleCancel = () => {
    reset(preloadedRestaurantValue);
    setEditId('');
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };

  const handleEditRestaurantMethod = (data) => {
    data.id = editId;
    data.is_veg = isVeg;
    data.is_dine_in = checkedValueDineIn;
    data.is_take_away = checkedValueTakeAway;
    data.is_delivery = checkedValueDelievery;
    data.dinein_prepaid = checkedValueDineInPrePaid;
    data.dinein_pospaid = checkedValueDineInPostPaid;
    data.take_away_online_payment = checkedValueTakeAwayOnlinePayment;
    data.take_away_cash_payment = checkedValueTakeAwayCashPayment;
    data.delivery_cash_on_delivery = checkedValueCashOnDelievery;
    data.delivery_online_payment = checkedValueDelieveryOnlinePayment;
    dispatch(
      updateCapabilitiesRestaurantAction(
        data,
        UpdateCallBack,
        faliureUpdate,
        IsEmpty
      )
    );
  };

  const UpdateCallBack = () => {
    setTabkey('1');
    dispatch(getRestaurant(`?page=${currentPage}`));
    setTimeout(() => {
      message.success('Your Restaurant Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const isLoading = isCreateRestaurantLoading || isUpdateRestaurantLoading;
  return (
    <Spin spinning={isLoading}>
      <Card
        style={{
          backgroundColor: '#f6f6f6',
          border: 'rgb(229, 229, 229,1)',
        }}
      >
        <form
          onSubmit={handleSubmit(handleEditRestaurantMethod)}
          style={{
            maxHeight: '500px',
            overflowX: 'hidden',
          }}
        >
          <>
            <Spin spinning={isUpdateRestaurantLoading}>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Row>
                    <Col span={1}>
                      <Controller
                        name='is_veg'
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <Checkbox
                            {...props}
                            checked={isVeg}
                            onChange={(e) =>
                              handleisVegMethod(e.target.checked)
                            }
                          />
                        )}
                      />
                    </Col>
                    <Col span={22}>
                      <Typography className='formHeading'>
                        Does this restaurant serves only vegetarian food&nbsp;?
                      </Typography>
                    </Col>
                  </Row>
                  <Row className='button'>
                    <Card className='card'>
                      <Row gutter={[18, 18]} justify='space-around'>
                        <Col span={2}>
                          <Button
                            onClick={handleDineInClick}
                            className='dawnButton'
                          >
                            {isDineInVisible ? (
                              <DownOutlined />
                            ) : (
                              <UpOutlined />
                            )}
                          </Button>
                        </Col>
                        <Col span={18}>
                          <Typography className='formHeading'>
                            Dine In
                          </Typography>
                        </Col>
                        <Col span={4} align='end'>
                          <Controller
                            name='is_dine_in'
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <Checkbox
                                {...props}
                                checked={checkedValueDineIn}
                                onChange={(e) =>
                                  onChangeDineIn(e.target.checked)
                                }
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      {isDineInVisible && (
                        <Row gutter={[18, 18]} justify='space-around'>
                          <Col span={24}>
                            <Typography className='breadcrumRestaurant'>
                              Turn on the billing types according to your needs.
                              (Turn on any one)
                            </Typography>
                          </Col>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Prepaid
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Controller
                              name='dinein_prepaid'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Switch
                                  className='switchButton'
                                  {...props}
                                  checked={checkedValueDineInPrePaid}
                                  size='small'
                                  onChange={onChangeDineInPrePaid}
                                />
                              )}
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='breadcrumRestaurant'>
                              Prepaid means customers pay for their order
                              upfront before receiving the service or meal
                            </Typography>
                          </Col>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Postpaid
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Controller
                              name='dinein_postpaid'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Switch
                                  className='switchButton'
                                  {...props}
                                  checked={checkedValueDineInPostPaid}
                                  size='small'
                                  onChange={onChangeDineInPostPaid}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      )}
                    </Card>
                  </Row>
                  <Row className='button'>
                    <Card className='card'>
                      <Row gutter={[18, 18]} justify='space-around'>
                        <Col span={2}>
                          <Button
                            onClick={handleTakeAwayClick}
                            className='dawnButton'
                          >
                            {isTakeAwayVisible ? (
                              <DownOutlined />
                            ) : (
                              <UpOutlined />
                            )}
                          </Button>
                        </Col>
                        <Col span={18}>
                          <Typography className='formHeading'>
                            Take Away
                          </Typography>
                        </Col>
                        <Col span={4} align='end'>
                          <Controller
                            name='is_take_away'
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <Checkbox
                                {...props}
                                checked={checkedValueTakeAway}
                                onChange={(e) =>
                                  onChangeTakeaway(e.target.checked)
                                }
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      {isTakeAwayVisible && (
                        <Row gutter={[18, 18]} justify='space-around'>
                          <Col span={24}>
                            <Typography className='breadcrumRestaurant'>
                              Turn on the billing types according to your needs
                            </Typography>
                          </Col>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Cash Payment
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Controller
                              name='take_away_cash_payment'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Switch
                                  className='switchButton'
                                  {...props}
                                  checked={checkedValueTakeAwayCashPayment}
                                  size='small'
                                  onChange={onChangeTakeawayCashPayment}
                                />
                              )}
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='breadcrumRestaurant'>
                              Customers place their takeaway order and pay at
                              the restaurant when they come to pick it up.
                            </Typography>
                          </Col>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Online Payment
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Controller
                              name='take_away_online_payment'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Switch
                                  className='switchButton'
                                  {...props}
                                  checked={checkedValueTakeAwayOnlinePayment}
                                  size='small'
                                  onChange={onChangeTakeawayOnlinePayment}
                                />
                              )}
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='breadcrumRestaurant'>
                              Customers pay for their takeaway order online or
                              in advance before arriving at the restaurant to
                              collect it.
                            </Typography>
                          </Col>
                        </Row>
                      )}
                    </Card>
                  </Row>
                  <Row className='button'>
                    <Card className='card'>
                      <Row gutter={[18, 18]} justify='space-around'>
                        <Col span={2}>
                          <Button
                            onClick={handleDelieveryClick}
                            className='dawnButton'
                          >
                            {isDelieveryVisible ? (
                              <DownOutlined />
                            ) : (
                              <UpOutlined />
                            )}
                          </Button>
                        </Col>
                        <Col span={18}>
                          <Typography className='formHeading'>
                            Delivery
                          </Typography>
                        </Col>
                        <Col span={4} align='end'>
                          <Controller
                            name='is_delivery'
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <Checkbox
                                {...props}
                                checked={checkedValueDelievery}
                                onChange={(e) =>
                                  onChangeDelievery(e.target.checked)
                                }
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      {isDelieveryVisible && (
                        <Row gutter={[18, 18]} justify='space-around'>
                          <Col span={24}>
                            <Typography className='breadcrumRestaurant'>
                              Turn on the payment options according to your
                              needs
                            </Typography>
                          </Col>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Cash on delivery
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Controller
                              name='delivery_cash_on_delivery'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Switch
                                  className='switchButton'
                                  {...props}
                                  checked={checkedValueCashOnDelievery}
                                  size='small'
                                  onChange={onChangeCashOnDelievery}
                                />
                              )}
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='breadcrumRestaurant'>
                              Customers can choose to pay in cash when the order
                              is delivered to them.
                            </Typography>
                          </Col>
                          <Col span={20}>
                            <Typography className='formHeading'>
                              Online payment
                            </Typography>
                          </Col>
                          <Col span={4} align='end'>
                            <Controller
                              name='delivery_online_payment'
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <Switch
                                  className='switchButton'
                                  {...props}
                                  checked={checkedValueDelieveryOnlinePayment}
                                  size='small'
                                  onChange={onChangeDelieveryOnlinePayment}
                                />
                              )}
                            />
                          </Col>
                          <Col span={24}>
                            <Typography className='breadcrumRestaurant'>
                              Customers pay immediately through digital methods
                              when placing the order.
                            </Typography>
                          </Col>
                        </Row>
                      )}
                    </Card>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[22, 22]} className='button' justify='center'>
                <Col span={24}></Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Button className='cancelButton' onClick={handleCancel}>
                    Cancel
                  </Button>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Button className='editDesignationButton' htmlType='submit'>
                    Update
                  </Button>
                </Col>
                <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
              </Row>
            </Spin>
          </>
        </form>
      </Card>
    </Spin>
  );
};

export default Capabilities;
