import constants from '../Utils/constants';
const initialState = {
  isallUsersloading: false,
  allUsers: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        isallUsersloading: true,
      };
    case constants.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        isallUsersloading: false,
        allUsers: action.payload,
      };
    case constants.GET_ALL_USERS_FAILURE:
      return {
        ...state,
        isallUsersloading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
