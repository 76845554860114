import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { SupplierSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getSupplier,
  createSupplier,
  updateSupplier,
} from '../../Actions/SupplierAction';
import { LuAsterisk } from 'react-icons/lu';
import { getAllState, getStateById } from '../../Actions/StateAction';
import { getCountryById } from '../../Actions/countryAction';

const SupplierModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, setCurrentPage } = props;
  const dispatch = useDispatch();
  const [preloadedCategoryValue, setPreloadedCategoryValue] = useState('');
  const {
    allSupplier,
    isSupplierloading,
    allCountries,
    country,
    State,
    allStates,
  } = useSelector((state) => {
    const { allSupplier, isSupplierloading } = state.supplier;
    const { allCountries, country } = state.country;
    const { State, allStates } = state.states;
    return {
      allSupplier,
      isSupplierloading,
      allCountries,
      country,
      State,
      allStates,
    };
  });
  const [countryId, setcountryId] = useState(country?.name);
  const [stateId, setstateId] = useState(State?.name);
  const [number, setNumber] = useState('');
  const [whatsappNumber, setNumberWhatsup] = useState('');
  const the_supplier = _.filter(allSupplier, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedCategoryValue,
    },
    resolver: yupResolver(SupplierSchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedCategoryValue(the_supplier[0]);
      reset(the_supplier[0]);
    }
  }, [the_supplier[0], reset]);
  const handleChange = (e) => {
    e.target.value = setNumber(e.target.value.replace(/\D/g, ''));
  };
  const handleChangeWhatsappnumber = (e) => {
    e.target.value = setNumberWhatsup(e.target.value.replace(/\D/g, ''));
  };
  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    clearErrors();
  };
  const handleCancel = () => {
    setEditId('');
    setValue('name', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedCategoryValue);
    clearErrors();
  };
  const handleCreate = (data) => {
    dispatch(createSupplier(data, successCreate, failureCreate));
  };
  const successCreate = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getSupplier(`api/suppliers`));
    setValue('name', '');
    setValue('published', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your Supplier Created Successfully');
    }, 1000);
  };

  const failureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const handleEdit = (data) => {
    data.id = editId;
    dispatch(updateSupplier(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    dispatch(getSupplier(`api/suppliers`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Supplier Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const selectCountry = (e) => {
    const countryObj = _.filter(allCountries?.countries, function (o) {
      return o.id === e;
    });
    setcountryId(countryObj[0]?.name);
    dispatch(getCountryById(countryObj[0]?.id));
    dispatch(getAllState(`api/states?country_id=${countryObj[0]?.id}`));
  };
  const selectState = (e) => {
    const countryObj = _.filter(allStates, function (o) {
      return o.id === e;
    });
    setstateId(countryObj[0]?.name);
    dispatch(getStateById(countryObj[0]?.id));
  };
  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);
  const [width] = useState(window.innerWidth);
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #f6f6f6 inset',
  };
  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
              className='breadcrumRestaurant'
            >
              Supplier
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update ' : 'Create New'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <form onSubmit={handleSubmit(editId ? handleEdit : handleCreate)}>
        <Spin spinning={isSupplierloading}>
          <Row gutter={[16, 16]}></Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                Supplier Name
                <LuAsterisk className='asterisk' />
              </Typography>
              <Controller
                as={<Input style={inputStyles} />}
                placeholder='Enter Supplier name'
                name='supplier_name'
                control={control}
                className='inputLabel'
              />
              {errors.supplier_name && (
                <p style={{ color: 'red' }}>{errors.supplier_name.message}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                Company Name
                <LuAsterisk className='asterisk' />
              </Typography>
              <Controller
                as={<Input style={inputStyles} />}
                placeholder='Enter company name'
                name='company_name'
                control={control}
                className='inputLabel'
              />
              {errors.company_name && (
                <p style={{ color: 'red' }}>{errors.company_name.message}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>Phone Number </Typography>
              <Controller
                as={
                  <Input
                    className='inputLabel'
                    maxLength={10}
                    onChange={handleChange}
                    value={number}
                  />
                }
                placeholder='Enter Phone Number'
                name='phone_number'
                control={control}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>Whatsapp Number </Typography>
              <Controller
                as={
                  <Input
                    className='inputLabel'
                    maxLength={10}
                    onChange={handleChangeWhatsappnumber}
                    value={whatsappNumber}
                  />
                }
                placeholder='Enter Whatsapp Number'
                name='whatsapp_number'
                control={control}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>Email Address </Typography>
              <Controller
                as={<Input style={inputStyles} />}
                placeholder='Enter supplier email address'
                name='email'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>gst_number </Typography>
              <Controller
                as={<Input style={inputStyles} />}
                placeholder='Enter supplier gst number'
                name='gst_number'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>fssai_lic_number </Typography>
              <Controller
                as={<Input style={inputStyles} />}
                placeholder='Enter supplier fssai license number'
                name='fssai_lic_number'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>Address </Typography>
              <Controller
                as={<Input style={inputStyles} />}
                placeholder='Enter Supplier address'
                name='address'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>Country</Typography>
              <Controller
                name='country_id'
                as={
                  <Select
                    placeholder='select country'
                    style={{ width: '100%', borderRadius: '4px' }}
                    showSearch={true}
                    className='selection'
                    options={allCountries?.countries?.map((val, i) => {
                      return {
                        label: val.name,
                        value: val.id,
                      };
                    })}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onSelect={selectCountry}
                  ></Select>
                }
                defaultValue={editId ? the_supplier[0]?.country?.id : ''}
                control={control}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>State</Typography>
              <Controller
                name='state_id'
                as={
                  <Select
                    style={{ width: '100%', borderRadius: '4px' }}
                    className='selection'
                    options={allStates?.map((val, i) => {
                      return {
                        label: val.name,
                        value: val.id,
                      };
                    })}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onSelect={selectState}
                  >
                    {' '}
                  </Select>
                }
                placeholder='Select State'
                defaultValue={editId ? the_supplier[0]?.state?.id : ''}
                control={control}
              />
            </Col>
            <Col
              xl={8}
              lg={8}
              md={8}
              sm={24}
              xs={24}
              className='formRows'
            ></Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>City </Typography>
              <Controller
                as={<Input style={inputStyles} />}
                placeholder='Enter your City'
                name='city'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>

          <Row gutter={[22, 22]} className='button' justify='center'>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              {editId ? (
                <Button className='cancelButton' onClick={handleeditCancel}>
                  Cancel
                </Button>
              ) : (
                <Button className='cancelButton' onClick={handleCancel}>
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              {editId ? (
                <Button className='detailsButton' htmlType='submit'>
                  Update
                </Button>
              ) : (
                <Button className='detailsButton' htmlType='submit'>
                  Create
                </Button>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
          </Row>
        </Spin>
      </form>
    </div>
  );
};

export default SupplierModalForm;
