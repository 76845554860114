import API from '../Utils/API';

export const getFeaturesFlag = () => (dispatch) => {
  dispatch({ type: 'GET_FEATURES_FLAG_REQUEST' });
  API.get(`api/organization_features_flags`)
    .then((response) => {
      dispatch({ type: 'GET_FEATURES_FLAG_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_FEATURES_FLAG_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const createFeaturesFlag =
  (data, successCreateFeatureFlag, failureCreateFeatureFlag) => (dispatch) => {
    dispatch({ type: 'CREATE_FEATURES_FLAG_REQUEST' });
    API.post('api/organization_features_flags', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_FEATURES_FLAG_SUCCESS',
          payload: response.data,
        });
        successCreateFeatureFlag && successCreateFeatureFlag();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_FEATURES_FLAG_FAILURE',
          payload: error.response.data,
        });
        failureCreateFeatureFlag &&
          failureCreateFeatureFlag(error.response.data);
      });
  };

export const updateFeaturesFlag =
  (data, successUpdateCallBack, failureUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_FEATURES_FLAG_REQUEST' });
    API.put(`api/organization_features_flags/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_FEATURES_FLAG_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_FEATURES_FLAG_FAILURE',
          payload: error.response.data,
        });
        failureUpdateCallBack && failureUpdateCallBack(error.response.data);
      });
  };

export const getFeaturesFlagById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_FEATURES_FLAG_BY_ID_REQUEST' });
  API.get(`api/organization_features_flags/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_FEATURES_FLAG_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_FEATURES_FLAG_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
