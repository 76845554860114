import React, { useEffect, useState } from 'react';
import './Restaurant.css';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  message,
  Card,
  Checkbox,
} from 'antd';
import {
  updatefeturesRestaurantAction,
  getRestaurantDetails,
  getRestaurant,
} from '../../Actions/RestaurantAction';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';

const Features = (props) => {
  const {
    editId,
    setEditId,
    setIsDefaultImageVisible,
    setShowUploadImage,
    setTabkey,
    currentPage,
  } = props;
  const dispatch = useDispatch();
  const [newFiles, setNewFiles] = useState();
  const IsEmpty = _.isEmpty(newFiles) ? true : false;
  const [preloadedRestaurantValue, setPreloadedRestaurantValue] = useState('');

  const {
    restaurantdeatils,
    allRestaurant,
    isCreateRestaurantLoading,
    isUpdateRestaurantLoading,
  } = useSelector((state) => {
    const {
      restaurantdeatils,
      allRestaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
    } = state.restaurant;
    return {
      restaurantdeatils,
      allRestaurant,
      isCreateRestaurantLoading,
      isUpdateRestaurantLoading,
    };
  });

  const { organizations } = allRestaurant;
  const the_restaurant = _.filter(organizations, function (o) {
    return o.id === editId;
  });

  const { handleSubmit, control, reset, setValue, clearErrors } = useForm({
    defaultValues: {
      // is_inventory: editId?.is_inventory,
      // is_workstation: editId?.is_workstation,
      // is_inventory: editId && the_restaurant[0].is_inventory,
      // is_workstation: editId && the_restaurant[0].is_workstation,
    },
  });
  //   const [isFssai, setisFssai] = useState(
  //     restaurantdeatils?.is_inventory ? restaurantdeatils?.is_fssai : false
  //   );
  const [checkedValueCashier, setCheckedValueCashier] = useState(
    editId && the_restaurant[0]?.is_cashier ? true : false
  );
  const [checkedValueIsDashboard, setCheckedValueIsDashboard] = useState(
    editId && the_restaurant[0]?.is_dashboard ? true : false
  );
  const [checkedValueInventory, setCheckedValueInventory] = useState(
    editId && the_restaurant[0]?.is_inventory ? true : false
  );
  const [checkedValueOrder, setCheckedValueOrder] = useState(
    editId && the_restaurant[0]?.is_orders ? true : false
  );
  const [checkedValueWorkstation, setCheckedValueWorkstation] = useState(
    editId && the_restaurant[0]?.is_workstation ? true : false
  );
  const [checkedValueSetting, setCheckedValueSetting] = useState(
    editId && the_restaurant[0]?.is_settings ? true : false
  );
  const [checkedValueMenu, setCheckedValueMenu] = useState(
    editId && the_restaurant[0]?.is_menu ? true : false
  );
  const [checkedValueSurveys, setCheckedValueSurveys] = useState(
    editId && the_restaurant[0]?.is_surveys ? true : false
  );
  const [checkedValueQueue, setCheckedValueQueue] = useState(
    editId && the_restaurant[0]?.is_queue ? true : false
  );
  const [checkedValueCustomer, setCheckedValueCustomer] = useState(
    editId && the_restaurant[0]?.is_customer ? true : false
  );
  const [checkedValueKds, setCheckedValueKds] = useState(
    editId && the_restaurant[0]?.is_kds ? true : false
  );
  const [checkedValueCaptain, setCheckedValueCaptain] = useState(
    editId && the_restaurant[0]?.is_captain ? true : false
  );
  const onChangeCashier = (e) => {
    setCheckedValueCashier(e);
  };
  const onChangeIsDashboard = (e) => {
    setCheckedValueIsDashboard(e);
  };
  const onChangeInventory = (e) => {
    setCheckedValueInventory(e);
  };
  const onChangeOrder = (e) => {
    setCheckedValueOrder(e);
  };
  const onChangeWorkstation = (e) => {
    setCheckedValueWorkstation(e);
  };
  const onChangeSetting = (e) => {
    setCheckedValueSetting(e);
  };
  const onChangeMenu = (e) => {
    setCheckedValueMenu(e);
  };
  const onChangeSurveys = (e) => {
    setCheckedValueSurveys(e);
  };
  const onChangeQueue = (e) => {
    setCheckedValueQueue(e);
  };
  const onChangeCustomer = (e) => {
    setCheckedValueCustomer(e);
  };
  const onChangeKds = (e) => {
    setCheckedValueKds(e);
  };
  const onChangeCaptain = (e) => {
    setCheckedValueCaptain(e);
  };

  const handleCancel = () => {
    reset(preloadedRestaurantValue);
    // setEditId('');
    setValue('name', '');
    setValue('legal_entity_name', '');
    setValue('published', '');
    setValue('org_logo', '');
    setValue('country_id', '');
    setValue('state_id', '');
    setValue('time_zone', '');
    setValue('email', '');
    setValue('country_code', '');
    setValue('phone', '');
    setValue('is_cafe', '');
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };

  useEffect(() => {
    if (editId) {
      dispatch(getRestaurantDetails());
    }
  }, [dispatch]);

  const handleEditRestaurantMethod = (data) => {
    data.id = editId;
    data.is_cashier = checkedValueCashier;
    data.is_dashboard = checkedValueIsDashboard;
    data.is_inventory = checkedValueInventory;
    data.is_orders = checkedValueOrder;
    data.is_workstation = checkedValueWorkstation;
    data.is_settings = checkedValueSetting;
    data.is_queue = checkedValueQueue;
    data.is_customer = checkedValueCustomer;
    data.is_kds = checkedValueKds;
    data.is_menu = checkedValueMenu;
    data.is_surveys = checkedValueSurveys;
    data.is_captain = checkedValueCaptain;
    dispatch(
      updatefeturesRestaurantAction(
        data,
        UpdateCallBack,
        faliureUpdate,
        IsEmpty
      )
    );
  };

  const UpdateCallBack = (data) => {
    setTabkey('1');
    // dispatch(getRestaurantDetails());
    dispatch(getRestaurant(`?page=${currentPage}`));
    setTimeout(() => {
      message.success('Your Restaurant Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  return (
    <Card
      style={{
        backgroundColor: '#f6f6f6',
        border: 'rgb(229, 229, 229,1)',
      }}
      // scrollable={{ x: 1000, y:500 }}
    >
      <form
        onSubmit={handleSubmit(handleEditRestaurantMethod)}
        // style={{ height: '100%' }}
      >
        <Spin spinning={isUpdateRestaurantLoading}>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                Organization Features
              </Typography>
              <Card className='card'>
                <Row gutter={[18, 18]} justify='space-around'>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_dashboard'
                          control={control}
                          defaultValue={true}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueIsDashboard}
                              onChange={(e) =>
                                onChangeIsDashboard(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>
                          Dashboard
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_workstation'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueWorkstation}
                              onChange={(e) =>
                                onChangeWorkstation(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>
                          Workstation
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_menu'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueMenu}
                              onChange={(e) => onChangeMenu(e.target.checked)}
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>Menu</Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_surveys'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueSurveys}
                              onChange={(e) =>
                                onChangeSurveys(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>Surveys</Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_inventory'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueInventory}
                              onChange={(e) =>
                                onChangeInventory(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>
                          Inventory
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_cashier'
                          control={control}
                          defaultValue={true}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueCashier}
                              onChange={(e) =>
                                onChangeCashier(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>Cashier</Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_queue'
                          control={control}
                          defaultValue={
                            editId ? the_restaurant[0]?.is_queue : false
                          }
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueQueue}
                              onChange={(e) => onChangeQueue(e.target.checked)}
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>Queue</Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_customer'
                          control={control}
                          defaultValue={true}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueCustomer}
                              onChange={(e) =>
                                onChangeCustomer(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>
                          Customer
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_orders'
                          control={control}
                          defaultValue={true}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueOrder}
                              onChange={(e) => onChangeOrder(e.target.checked)}
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>Orders</Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_settings'
                          control={control}
                          defaultValue={true}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueSetting}
                              onChange={(e) =>
                                onChangeSetting(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>
                          Settings
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_kds'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueKds}
                              onChange={(e) => onChangeKds(e.target.checked)}
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>KDS</Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Row gutter={[8, 8]}>
                      <Col span={4}>
                        <Controller
                          name='is_captain'
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <Checkbox
                              {...props}
                              checked={checkedValueCaptain}
                              onChange={(e) =>
                                onChangeCaptain(e.target.checked)
                              }
                            />
                          )}
                        />
                      </Col>

                      <Col span={20}>
                        <Typography className='formHeading'>Captain</Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row gutter={[22, 22]} className='button' justify='center'>
            <Col span={24}></Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Button className='cancelButton' onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Button className='editDesignationButton' htmlType='submit'>
                Update
              </Button>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
          </Row>
        </Spin>
      </form>
    </Card>
  );
};

export default Features;
