import constants from '../Utils/constants';
const initialState = {
  isWaitersLoading: false,
  isCreateWaiterLoading: false,
  isGetWaiterByIdLoading: false,
  isUpdateWaiterLoading: false,
  isDeleteWaiterLoading: false,
  allWaiters: [],
  Waiter: {},
};

export const waiterReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_WAITERS_REQUEST:
      return {
        ...state,
        isWaitersLoading: true,
      };
    case constants.GET_WAITERS_SUCCESS:
      return {
        ...state,
        isWaitersLoading: false,
        allWaiters: action.payload,
      };
    case constants.GET_WAITERS_FAILURE:
      return {
        ...state,
        isWaitersLoading: false,
      };
    case constants.CREATE_WAITER_REQUEST:
      return {
        ...state,
        isCreateWaiterLoading: true,
      };
    case constants.CREATE_WAITER_SUCCESS:
      return {
        ...state,
        isCreateWaiterLoading: false,
      };
    case constants.CREATE_WAITER_FAILURE:
      return {
        ...state,
        isCreateWaiterLoading: false,
      };
    case constants.UPDATE_WAITER_REQUEST:
      return {
        ...state,
        isUpdateWaiterLoading: true,
      };
    case constants.UPDATE_WAITER_SUCCESS:
      return {
        ...state,
        isUpdateWaiterLoading: false,
      };
    case constants.UPDATE_WAITER_FAILURE:
      return {
        ...state,
        isUpdateWaiterLoading: false,
      };
    case constants.DELETE_WAITER_REQUEST:
      return {
        ...state,
        isDeleteWaiterLoading: true,
      };
    case constants.DELETE_WAITER_SUCCESS:
      return {
        ...state,
        isDeleteWaiterLoading: false,
      };
    case constants.DELETE_WAITER_FAILURE:
      return {
        ...state,
        isDeleteWaiterLoading: false,
      };
    case constants.GET_WAITER_BY_ID_REQUEST:
      return {
        ...state,
        isGetWaiterByIdLoading: true,
      };
    case constants.GET_WAITER_BY_ID_SUCCESS:
      return {
        ...state,
        isGetWaiterByIdLoading: false,
        Waiter: action.payload,
      };
    case constants.GET_WAITER_BY_ID_FAILURE:
      return {
        ...state,
        isGetWaiterByIdLoading: false,
      };
    default:
      return state;
  }
};
