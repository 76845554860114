import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  Spin,
  Tooltip,
  Breadcrumb,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import { useNavigate } from 'react-router-dom';
import { getAllUsers } from '../../Actions/UserAction';
import SearchComponent from '../../Utils/SearchComponent';

const Users = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { allUsers, isallUsersloading } = useSelector((state) => {
    const { allUsers, isallUsersloading } = state.user;
    return {
      isallUsersloading,
      allUsers,
    };
  });
  const { length } = allUsers || {};
  useEffect(() => {
    dispatch(getAllUsers(`?page=${currentPage}`));
  }, [currentPage]);

  const Columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      width: '7%',
      render: (id, { first_name, last_name }) => {
        return (
          <Row>
            <Col>
              <Typography
                style={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {first_name || last_name ? (
                  <>
                    <span style={{ display: 'block' }}>
                      {first_name} {last_name}
                    </span>
                    {/* <span style={{ display: 'block' }}>{last_name}</span> */}
                  </>
                ) : (
                  <span style={{ display: 'block' }}>-</span>
                )}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Phone number',
      dataIndex: 'phone',
      width: '7%',
      render: (id, { phone, country_code }) => {
        return (
          <Row>
            <Col>
              <Typography style={{ whiteSpace: 'nowrap' }}>
                {country_code} {phone}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '5%',
      render: (email) => {
        return (
          <Row>
            <Col>
              <Typography style={{ whiteSpace: 'nowrap' }}>
                {email || '-'}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Onboarding Count',
      dataIndex: 'onboarding_step_count',
      width: '4%',
      render: (onboarding_step_count) => {
        return (
          <Row>
            <Col>
              <Typography>{onboarding_step_count || '-'}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Onboarding?',
      dataIndex: 'is_onboarding',
      width: '4%',
      render: (is_onboarding) => {
        return (
          <Row>
            <Col>
              <Typography>{is_onboarding ? 'Yes' : 'No'}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Otp Count',
      dataIndex: 'otp_attampt_count',
      width: '4%',
      render: (otp_attampt_count) => {
        return (
          <Row>
            <Col>
              <Typography>{otp_attampt_count}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Otp date',
      dataIndex: 'otp_attampt_datetime',
      width: '4%',
      render: (otp_attampt_datetime) => {
        return (
          <Row>
            <Col>
              <Typography>{otp_attampt_datetime || '-'}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Super admin?',
      dataIndex: 'is_super_admin',
      width: '5%',
      render: (is_super_admin) => {
        return (
          <Row>
            <Col>
              <Typography>{is_super_admin ? 'Yes' : 'No'}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Razorpay Customer id',
      dataIndex: 'razorpay_customer_id',
      width: '7%',
      render: (razorpay_customer_id) => {
        return (
          <Row>
            <Col>
              <Typography>{razorpay_customer_id || '-'}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Organization',
      dataIndex: 'user_organizations',
      width: '10%',
      render: (user_organizations) => {
        return (
          <Row>
            <Typography style={{ wordSpacing: '-4px', whiteSpace: 'nowrap' }}>
              {user_organizations?.map((org, index) => (
                <div
                  key={index}
                  style={{ marginBottom: '10px', whiteSpace: 'nowrap' }}
                >
                  <span>• </span>
                  <span
                    onClick={() =>
                      navigate(`/restaurants/${org.organization.id}`)
                    }
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    {org.organization.name}
                  </span>{' '}
                  as
                  {org.organization_users
                    .flatMap((user) => user.user_type)
                    .map((role, idx) => (
                      <span
                        key={idx}
                        style={{
                          margin: '0 5px',
                          padding: '3px 7px',
                          border: '1px solid black',
                          borderRadius: '20px',
                        }}
                      >
                        {role}
                      </span>
                    ))}
                </div>
              ))}
            </Typography>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={isallUsersloading}>
      <Row gutter={[0, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row gutter={[0, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb>
                <Breadcrumb.Item className='breadcrumRestaurant'>
                  Users
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={14}>
              <Typography className='heading'>Users ({length})</Typography>
            </Col>
            <Col span={10}>
              <Row justify='end' gutter={[8, 8]}>
                <Col span={24}>
                  <SearchComponent
                    search={[
                      // { key: 'name', label: 'Name' },
                      { key: 'phone', label: 'Phone' },
                      { key: 'user_type', label: 'User type' },
                    ]}
                    placeholder='Search by User Phone/User type'
                    searchMethod={getAllUsers}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <CustomTable
                columns={Columns}
                currentPage={currentPage}
                data={allUsers?.users}
              />
            </Col>
            {allUsers?.users && length > 0 ? (
              <Col span={24}>
                <Row justify='center'>
                  <Pagination
                    total={allUsers?.length}
                    onChange={(e) => setCurrentPage(e)}
                    responsive={true}
                    size='large'
                    current={currentPage}
                    showSizeChanger={false}
                  />
                </Row>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default Users;
