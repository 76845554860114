import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Spin,
  Tooltip,
  Button,
  Breadcrumb,
  message,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { getFeatureById, getFeatures } from '../../Actions/FeaturesAction';
import {
  deleteLanguage,
  getLanguageById,
  getLanguages,
} from '../../Actions/LanguagesAction';
import LanguageModalForm from './LanguageModalForm';
import { useNavigate, useParams } from 'react-router';
import LanguageDetails from './LanguageDetails';

const Languages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const {
    restaurant,
    allLanguages,
    isLanguagesLoading,
    isDeleteLanguageLoading,
  } = useSelector((state) => {
    const { allLanguages, isLanguagesLoading, isDeleteLanguageLoading } =
      state.languages;
    const { restaurant } = state.restaurant;
    return {
      restaurant,
      isLanguagesLoading,
      allLanguages,
      isDeleteLanguageLoading,
    };
  });
  //   const { length } = allLanguages || {};
  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch]);

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleOpen = (Id) => {
    dispatch(getLanguageById(Id));
    setDrawerVisible(true);
  };
  const handleAddNewLanguage = () => {
    setIsModalVisible(true);
    setEditId('');
  };

  const successDeleteLanguage = () => {
    dispatch(getLanguages());
    setTimeout(() => {
      message.success(`Your Language Deleted Successfully`);
    }, 1000);
  };
  const Columns = [
    {
      title: 'Language Name',
      dataIndex: 'name',
      width: '10%',
      render: (name, { id }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      width: '15%',
      render: (code) => {
        return (
          <Row>
            <Col>
              <Typography>{code}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '2%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteLanguage]}
                customMethod={successDeleteLanguage}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isLanguagesLoading || isDeleteLanguageLoading}>
      <Row gutter={[0, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <LanguageModalForm
              allLanguages={allLanguages}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              restaurant={restaurant}
            ></LanguageModalForm>
          ) : (
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Languages
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Languages</Typography>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleAddNewLanguage}
                    >
                      Add new Language
                    </Button>
                  </Col>
                </Row>
              </Col>
              <LanguageDetails
                setDrawerVisible={setDrawerVisible}
                drawerVisible={drawerVisible}
              ></LanguageDetails>
              <Col span={24}>
                <CustomTable columns={Columns} data={allLanguages} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Languages;
