import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Select,
  Radio,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useDispatch, useSelector } from 'react-redux';
import { FaStarOfLife } from 'react-icons/fa6';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { CaretDownOutlined } from '@ant-design/icons';
import {
  createFeaturesFlag,
  getFeaturesFlag,
  updateFeaturesFlag,
} from '../../Actions/FeaturesFlagAction';
import { featuresFlagSchema } from '../../Utils/Schema';
import { useNavigate, useParams } from 'react-router';

const FeaturesFlagModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, allFeaturesFlag } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const {
    restaurant,
    allFeatures,
    isCreateFeaturesFlagLoading,
    isUpdateFeaturesFlagLoading,
  } = useSelector((state) => {
    const { allFeatures } = state.feature;
    const { restaurant } = state.restaurant;
    const { isCreateFeaturesFlagLoading, isUpdateFeaturesFlagLoading } =
      state.featuresFlag;
    return {
      allFeatures,
      restaurant,
      isCreateFeaturesFlagLoading,
      isUpdateFeaturesFlagLoading,
    };
  });
  const the_feature_flag = _.filter(allFeaturesFlag, function (o) {
    return o.id === editId;
  });
  const [selectedFeaturesFlag, setSelectedFeaturesFlag] = useState({
    label: editId && the_feature_flag[0]?.feature?.name,
    value: editId && the_feature_flag[0]?.feature?.id,
  });
  const [checkedValueActive, setCheckedValueActive] = useState(
    editId && the_feature_flag[0]?.active ? true : false
  );

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      feature_id: the_feature_flag[0]?.feature?.name,
    },
    resolver: yupResolver(featuresFlagSchema),
  });

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setSelectedFeaturesFlag({
      label: '',
      value: '',
    });
    clearErrors();
  };
  const handleCancel = () => {
    setEditId('');
    setSelectedFeaturesFlag({
      label: '',
      value: '',
    });
    clearErrors();
  };

  const handleeditCancel = () => {
    reset();
    clearErrors();
  };
  const handleCreateFeaturesFlag = (data) => {
    data.feature_id = selectedFeaturesFlag.value;
    data.active = checkedValueActive;
    dispatch(
      createFeaturesFlag(
        data,
        successCreateFeaturesFlag,
        failureCreateFeaturesFlag
      )
    );
  };

  const successCreateFeaturesFlag = () => {
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getFeaturesFlag());
    clearErrors();
    setTimeout(() => {
      message.success('Your Features Flag Created Successfully');
    }, 1000);
  };

  const failureCreateFeaturesFlag = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleEdit = (data) => {
    data.id = editId;
    data.feature_id = selectedFeaturesFlag.value;
    data.active = checkedValueActive;
    dispatch(updateFeaturesFlag(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('description', '');
    dispatch(getFeaturesFlag());
    clearErrors();
    setTimeout(() => {
      message.success('Your Feature Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const filterFeature = allFeatures?.filter((features) =>
    allFeaturesFlag.every(
      (featuresFlag) => featuresFlag?.feature?.id !== features.id
    )
  );
  const options = filterFeature?.map((data) => {
    return { label: data?.name, value: data?.id };
  });

  const handleSelectFeatureFlag = (e) => {
    setValue('feature_id', !e, { shouldDirty: true });
    setSelectedFeaturesFlag({
      label: e,
      value: e,
    });
  };

  const onChangeActive = (e) => {
    setValue('active', !e, { shouldDirty: true });
    setCheckedValueActive(e.target.value);
  };

  return (
    <Spin spinning={isCreateFeaturesFlagLoading || isUpdateFeaturesFlagLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurantDrawer()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              className='breadcrumRestaurant'
            >
              Features Flag
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update Features Flag' : 'Add new Features Flag'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {' '}
            {editId ? 'Update Features Flag' : 'Add new Features Flag'}
          </Typography>
        </Col>
      </Row>
      <form
        onSubmit={handleSubmit(editId ? handleEdit : handleCreateFeaturesFlag)}
      >
        <Row gutter={[16, 16]} className='button'>
          <Col span={16}>
            <Typography className='formHeading'>
              Features Flag
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
            <Controller
              render={() => (
                <Select
                  size='large'
                  showArrow
                  suffixIcon={
                    <CaretDownOutlined
                      style={{ fontSize: '16px', color: '#7B7B7B' }}
                    />
                  }
                  style={{
                    width: '100%',
                  }}
                  placeholder='Please select Features Flag'
                  defaultValue={selectedFeaturesFlag?.label || undefined}
                  onChange={(e) => handleSelectFeatureFlag(e)}
                  options={options}
                />
              )}
              name='feature_id'
              control={control}
              className='inputLabel'
            />
            {errors.feature_id && (
              <p style={{ color: '#eb5757' }}>{errors.feature_id.message}</p>
            )}
          </Col>

          <Col span={16}>
            <Typography className='formHeading'>Active</Typography>
            <Controller
              name='active'
              control={control}
              render={() => (
                <Radio.Group
                  onChange={onChangeActive}
                  value={checkedValueActive}
                >
                  <Radio className='textRadio' value={true}>
                    Yes
                  </Radio>
                  <Radio className='textRadio' value={false}>
                    No
                  </Radio>
                </Radio.Group>
              )}
            />

            {/* <Radio.Group onChange={onChangeActive} value={checkedValueActive}>
              <Radio className='textRadio' value={true}>
                Yes
              </Radio>
              <Radio className='textRadio' value={false}>
                No
              </Radio>
            </Radio.Group> */}
          </Col>

          <Col span={16}>
            <Row gutter={[16, 16]} className='button' justify='center'>
              <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    className={isDirty ? 'cancelButton' : 'cancelButtonDirty'}
                    onClick={handleeditCancel}
                    size='large'
                    disabled={!isDirty}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className='cancelButton'
                    onClick={handleCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    size='large'
                    className={isDirty ? 'detailsButton' : 'classButton'}
                    htmlType='submit'
                    disabled={!isDirty}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </Spin>
  );
};

export default FeaturesFlagModalForm;
