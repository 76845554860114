import * as yup from 'yup';

export const createrestaurantSchema = yup.object().shape({
  name: yup
    .string()
    .required('Restaurant name is required')
    .matches(/[A-Z]/, 'Enter first letter capital'),
  // .matches(
  //   /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
  //   "This field cannot contain white space and special character"
  // ),
  legal_entity_name: yup.string().required('Legal Entity Name is required'),
  country_id: yup.string().required('Country name is required'),
  state_id: yup.string().required('State name is required'),
  time_zone: yup.string().required('Time zone is required'),
  mobile: yup.number().typeError('Mobile number is required'),
  email: yup.string().required('Email is required'),
});

export const editSchema = yup.object().shape({
  name: yup.string().required('Restaurant name is required'),
  // .matches(
  //   /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
  //   "This field cannot contain white space and special character"
  // ),
});
export const countrySchema = yup.object().shape({
  name: yup
    .string()
    .required('Country name is required')
    .matches(/[A-Z]/, 'Enter first letter capital'),
  // .matches(
  //   /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
  //   "This field cannot contain white space and special character"
  // ),
  code: yup.string().required('Code is required'),
  currency_code: yup.string().required('Currency Code is required'),
  country_code: yup
    .string()
    .required('Country Code is required')
    .matches(
      /^[+][0-9]*$/,
      'Please enter a plus sign (+) followed by numbers only'
    ),
});
export const stateSchema = yup.object().shape({
  name: yup
    .string()
    .required('State name is required')
    .matches(/[A-Z]/, 'Enter first letter capital'),
  // .matches(
  //   /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
  //   "This field cannot contain white space and special character"
  // ),
  code: yup.string().required('Code is required'),
});
export const taxationSchema = yup.object().shape({
  taxation_name: yup
    .string()
    .required('Taxation name is required')
    .matches(/[A-Z]/, 'Enter first letter capital'),
  // .matches(
  //   /^(?![0-9])(?!.*[0-9]$)(?!.*\d_)(?!.*_\d)[a-zA-Z0-9]+$/,
  //   "This field cannot contain white space and special character"
  // ),
  tax_rate: yup
    .string()
    .required('Tax Rate is required')
    .matches(/[0-9]/, 'This field accept only numbers.'),
  taxation_type: yup.string().required('Taxation Type is required'),
  resource_type: yup.string().required('Resource Type is required'),
});
export const categorySchema = yup.object().shape({
  name: yup.string().required('Category name is required'),
});
export const QrcodeSchema = yup.object().shape({
  name: yup.string().required('QR Code is required'),
  // qr_type: yup
  // .string()
  // .required("QR Type is required")
});
export const stationSchema = yup.object().shape({
  name: yup.string().required('Station name is required'),
});
export const paymentModeSchema = yup.object().shape({
  mode: yup.string().required('Payment Mode is required'),
});
export const orderProcessSchema = yup.object().shape({
  name: yup.string().required('Order Process name is required'),
});
export const surveySchema = yup.object().shape({
  name: yup.string().required('Survey name is required'),
  // .matches(/[A-Z]/,"Enter first letter capital"),

  after_survey_content: yup
    .string()
    .required('After Survey content is required'),
  introduction: yup.string().required('Introduction is required'),
});
export const categoryItemsSchema = yup.object().shape({
  price: yup.string().required('Price is required'),
  name: yup.string().required('Item name is required'),
  description: yup.string().required('Description is required'),
});
export const employeeSchema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
});
export const layoutSchema = yup.object().shape({
  layout_name: yup.string().required('Layout name is required'),
});
export const TableSchema = yup.object().shape({
  table_number: yup.string().required('Table Number is required'),
  seating_capacity: yup.number().required('Seating Capacity is required'),
  // organization_layout_id: yup
  //   .string()
  //   .required('Please Select atleast one Layout'),
});
export const userThemeSchema = yup.object().shape({
  primary_color: yup.string().required('Primary Color is required'),
  secondary_color: yup.string().required('Secondary Color is required'),
});
export const customizationSchema = yup.object().shape({
  name: yup.string().required('Customization Name is required'),
  max_selected: yup.string().required('Max Selected Items Allowed is required'),
});
export const customizationItemSchema = yup.object().shape({
  name: yup.string().required('Customization Item Name is required'),
  price: yup.string().required('Price is required'),
});
export const surveyQuestionSchema = yup.object().shape({
  question_text: yup.string().required('Question Text is required'),
  type: yup.string().required('Question type is required'),
});
export const orderProcessNotificationSchema = yup.object().shape({
  notification_text: yup.string().required('Notification text is required'),
});

export const SupplierSchema = yup.object().shape({
  supplier_name: yup.string().required('Supplier name is required'),
  company_name: yup.string().required('Company name is required'),
});
export const featureSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
});
export const featuresFlagSchema = yup.object().shape({
  feature_id: yup.string().required('Please Select atleast one Feature'),
});
export const languagesSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  code: yup.string().required('Code is required'),
});
export const waiterSchema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  waiter_code: yup.string().required('Waiter code is required'),
});
