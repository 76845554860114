import API from '../Utils/API';

export const getLanguages = () => (dispatch) => {
  dispatch({ type: 'GET_LANGUAGES_REQUEST' });
  API.get(`api/organization_languages`)
    .then((response) => {
      dispatch({ type: 'GET_LANGUAGES_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_LANGUAGES_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const createLanguage =
  (data, successCreatelanguage, failureCreatelanguage) => (dispatch) => {
    dispatch({ type: 'CREATE_LANGUAGE_REQUEST' });
    API.post('api/organization_languages', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_LANGUAGE_SUCCESS',
          payload: response.data,
        });
        successCreatelanguage && successCreatelanguage();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_LANGUAGE_FAILURE',
          payload: error.response.data,
        });
        failureCreatelanguage && failureCreatelanguage(error.response.data);
      });
  };

export const updateLanguage =
  (data, successUpdateCallBack, failureUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_LANGUAGE_REQUEST' });
    API.put(`api/organization_languages/${data.id}`, data)
      .then((response) => {
        dispatch({ type: 'UPDATE_LANGUAGE_SUCCESS', payload: response.data });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_LANGUAGE_FAILURE',
          payload: error.response.data,
        });
        failureUpdateCallBack && failureUpdateCallBack(error.response.data);
      });
  };

export const getLanguageById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_LANGUAGE_BY_ID_REQUEST' });
  API.get(`api/organization_languages/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_LANGUAGE_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_LANGUAGE_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};

export const deleteLanguage =
  (languaugeId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_LANGUAGE_REQUEST' });
    API.delete(`/api/organization_languages/${languaugeId}`)
      .then((response) => {
        dispatch({ type: 'DELETE_LANGUAGE_SUCCESS', payload: response.data });
        successDeleteCallback && successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_LANGUAGE_FAILURE',
          payload: error.response.data,
        });
      });
  };
