import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Spin,
  Tooltip,
  Button,
  Breadcrumb,
  message,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getFeaturesFlag,
  getFeaturesFlagById,
} from '../../Actions/FeaturesFlagAction';
import { getFeatures } from '../../Actions/FeaturesAction';
import { useNavigate, useParams } from 'react-router';
import {
  deleteWaiter,
  getWaiterById,
  getWaiters,
} from '../../Actions/WaitersAction';
import WaiterModalForm from './WaiterModalForm';
import WaiterDetails from './WaiterDetails';

const Waiters = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { restaurant, allWaiters, isWaitersLoading, isDeleteWaiterLoading } =
    useSelector((state) => {
      const { allWaiters, isWaitersLoading, isDeleteWaiterLoading } =
        state.waiter;
      const { restaurant } = state.restaurant;
      return {
        restaurant,
        isWaitersLoading,
        allWaiters,
        isDeleteWaiterLoading,
      };
    });

  useEffect(() => {
    dispatch(getWaiters());
  }, [dispatch]);

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleOpen = (Id) => {
    dispatch(getWaiterById(Id));
    setDrawerVisible(true);
  };
  const handleAddNewWaiter = () => {
    setIsModalVisible(true);
    setEditId('');
  };
  const successDeleteWaiter = () => {
    dispatch(getWaiters());
    setTimeout(() => {
      message.success(`Your Waiter Deleted Successfully`);
    }, 1000);
  };

  const Columns = [
    {
      title: 'Waiters Name',
      dataIndex: 'first_name',
      width: '10%',
      render: (Id, { id, first_name, last_name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${first_name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {first_name} {last_name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Code',
      dataIndex: 'waiter_code',
      width: '15%',
      render: (waiter_code) => {
        return (
          <Row>
            <Col>
              <Typography>{waiter_code}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '2%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteWaiter]}
                customMethod={successDeleteWaiter}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isWaitersLoading || isDeleteWaiterLoading}>
      <Row gutter={[0, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <WaiterModalForm
              restaurant={restaurant}
              allWaiters={allWaiters}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
            ></WaiterModalForm>
          ) : (
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Waiters
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Waiters</Typography>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleAddNewWaiter}
                    >
                      Add new Waiter
                    </Button>
                  </Col>
                </Row>
              </Col>
              <WaiterDetails
                setDrawerVisible={setDrawerVisible}
                drawerVisible={drawerVisible}
              ></WaiterDetails>
              <Col span={24}>
                <CustomTable columns={Columns} data={allWaiters} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Waiters;
