import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useDispatch, useSelector } from 'react-redux';
import { FaStarOfLife } from 'react-icons/fa6';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { categorySchema, featureSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createFeature,
  getFeatures,
  updateFeature,
} from '../../Actions/FeaturesAction';
import TextArea from 'antd/lib/input/TextArea';
const FeatureModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, allFeatures } = props;
  const dispatch = useDispatch();
  const { isCreateFeatureLoading, isUpdateFeatureLoading } = useSelector(
    (state) => {
      const { isCreateFeatureLoading, isUpdateFeatureLoading } = state.feature;
      return {
        isCreateFeatureLoading,
        isUpdateFeatureLoading,
      };
    }
  );
  const the_feature = _.filter(allFeatures, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: the_feature[0]?.name,
      description: the_feature[0]?.description,
    },
    resolver: yupResolver(featureSchema),
  });

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('description', '');
    clearErrors();
  };
  const handleCancel = () => {
    setEditId('');
    setValue('name', '');
    setValue('description', '');
    clearErrors();
  };

  const handleeditCancel = () => {
    reset();
    clearErrors();
  };
  const handleCreateFeature = (data) => {
    dispatch(createFeature(data, successCreateFeature, failureCreateFeature));
  };
  const successCreateFeature = () => {
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getFeatures());
    setValue('name', '');
    setValue('description', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your Feature Created Successfully');
    }, 1000);
  };

  const failureCreateFeature = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleEdit = (data) => {
    data.id = editId;
    dispatch(updateFeature(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('description', '');
    dispatch(getFeatures());
    clearErrors();
    setTimeout(() => {
      message.success('Your Feature Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  return (
    <Spin spinning={isCreateFeatureLoading || isUpdateFeatureLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              className='breadcrumRestaurant'
            >
              Features
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update Feature' : 'Add new Feature'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {' '}
            {editId ? 'Update Feature' : 'Add new Feature'}
          </Typography>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(editId ? handleEdit : handleCreateFeature)}>
        <Row gutter={[16, 16]} className='button'>
          <Col span={16}>
            <Typography className='formHeading'>
              Name
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
            <Controller
              as={<Input size='large' disabled={editId} />}
              name='name'
              control={control}
              className='inputLabel'
            />
            {errors.name && (
              <p style={{ color: '#eb5757' }}>{errors.name.message}</p>
            )}
          </Col>

          <Col span={16}>
            <Typography className='formHeading'>Description</Typography>
            <Controller
              as={<TextArea size='large' />}
              name='description'
              control={control}
              className='inputLabel'
            />
          </Col>

          <Col span={16}>
            <Row gutter={[16, 16]} className='button' justify='center'>
              <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    className={isDirty ? 'cancelButton' : 'cancelButtonDirty'}
                    onClick={handleeditCancel}
                    size='large'
                    disabled={!isDirty}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className='cancelButton'
                    onClick={handleCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    size='large'
                    className={isDirty ? 'detailsButton' : 'classButton'}
                    htmlType='submit'
                    disabled={!isDirty}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </Spin>
  );
};

export default FeatureModalForm;
