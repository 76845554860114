import constants from '../Utils/constants';
const initialState = {
  isFeatureLoading: false,
  isCreateFeatureLoading: false,
  isGetFeatureByIdLoading: false,
  isUpdateFeatureLoading: false,
  allFeatures: [],
  Feature: {},
};

export const featuresReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_FEATURES_REQUEST:
      return {
        ...state,
        isFeatureLoading: true,
      };
    case constants.GET_FEATURES_SUCCESS:
      return {
        ...state,
        isFeatureLoading: false,
        allFeatures: action.payload,
      };
    case constants.GET_FEATURES_FAILURE:
      return {
        ...state,
        isFeatureLoading: false,
      };
    case constants.CREATE_FEATURE_REQUEST:
      return {
        ...state,
        isCreateFeatureLoading: true,
      };
    case constants.CREATE_FEATURE_SUCCESS:
      return {
        ...state,
        isCreateFeatureLoading: false,
      };
    case constants.CREATE_FEATURE_FAILURE:
      return {
        ...state,
        isCreateFeatureLoading: false,
      };
    case constants.UPDATE_FEATURE_REQUEST:
      return {
        ...state,
        isUpdateFeatureLoading: true,
      };
    case constants.UPDATE_FEATURE_SUCCESS:
      return {
        ...state,
        isUpdateFeatureLoading: false,
      };
    case constants.UPDATE_FEATURE_FAILURE:
      return {
        ...state,
        isUpdateFeatureLoading: false,
      };
    case constants.GET_FEATURE_BY_ID_REQUEST:
      return {
        ...state,
        isGetFeatureByIdLoading: true,
      };
    case constants.GET_FEATURE_BY_ID_SUCCESS:
      return {
        ...state,
        isGetFeatureByIdLoading: false,
        Feature: action.payload,
      };
    case constants.GET_FEATURE_BY_ID_FAILURE:
      return {
        ...state,
        isGetFeatureByIdLoading: false,
      };
    default:
      return state;
  }
};
