import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Breadcrumb,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { getSupplier, deleteSupplier } from '../../Actions/SupplierAction';
import SupplierModalForm from './SupplierModalForm';
import { useNavigate, useParams } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import filtericon from '../../assests/filtericon.svg';
import dragvertical from '../../assests/dragvertical.svg';
import { SortableHandle } from 'react-sortable-hoc';

const Supplier = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedRow, setSelectedRow] = useState(null);
  const { restaurant, allSupplier, isSupplierloading } = useSelector(
    (state) => {
      const { allSupplier, isSupplierloading } = state.supplier;
      const { restaurant } = state.restaurant;
      return {
        restaurant,
        allSupplier,
        isSupplierloading,
      };
    }
  );

  const { length } = allSupplier || {};
  useEffect(() => {
    dispatch(getSupplier(`api/suppliers`));
  }, [dispatch, currentPage]);
  const handleSortButtonClick = () => {
    const sortedResults = [...allSupplier].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (sortOrder === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    setSortedData(sortedResults);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`Supplier Delete Successfully`);
    }, 1000);
    dispatch(getSupplier(`api/suppliers`));
  };
  const { orgId } = useParams();
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleCreate = (id) => {
    setIsModalVisible(id);
    setEditId(false);
  };

  const DragHandle = SortableHandle(() => (
    <img src={dragvertical} style={{ cursor: 'pointer' }} />
  ));

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const Columns = [
    {
      className: 'drag-visible',
      width: '4%',
      render: () => <DragHandle />,
    },
    {
      title: 'Supplier Name',
      dataIndex: 'supplier_name',
      className: 'drag-visible-name',
      width: '18%',
      render: (Id, { id, supplier_name }) => {
        return (
          <Row>
            <Col>
              <Typography>{supplier_name}</Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Company Name',
      dataIndex: 'company_name',
      className: 'drag-visible-name',
      width: '18%',
      render: (Id, { id, company_name }) => {
        return (
          <Row>
            <Col>
              <Typography>{company_name}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      className: 'drag-visible-action',
      width: '5%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteSupplier]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isSupplierloading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <SupplierModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></SupplierModalForm>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Supplier
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Supplier</Typography>
              </Col>

              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleCreate}
                      icon={<img src={plus} alt='props' />}
                    >
                      Create New
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className='filterButton'
                      onClick={handleSortButtonClick}
                      icon={<img src={filtericon} alt='props' />}
                    >
                      Filter
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <CustomTable
                  type='category'
                  columns={Columns}
                  currentPage={currentPage}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={sortOrder === 'asc' ? allSupplier : sortedData}
                />
              </Col>

              {allSupplier && length > 0 ? (
                <Col span={24}>
                  <Row justify='center'>
                    <Pagination
                      total={allSupplier?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Supplier;
