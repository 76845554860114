import { falseyValueCases } from '../Screens/CommonLogics/CommonMethods';
import constants from '../Utils/constants';
const initialState = {
  isRestaurantloading: false,
  allRestaurant: [],
  restaurantdeatils: {},
  restaurantdetailsId: {},
  restaurant: {},
  setRestaurantId: '',
  isRestaurantByIdLoading: false,
  isUpdateRestaurantLoading: false,
  isCreateRestaurantLoading: false,
  RestaurantError: '',
  isUpdateUserRestaurantLoading: false,
  isDeleteRestaurantLoading: false,
  isUpdateBrandingLogoLoading: false,
  visible2: false,
  visible3: true,
};

export const restaurantReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.TOGGLE_VISIBILITY:
      return {
        ...state,
        visible2: !state.visible2,
      };
    case constants.TOGGLE_VISIBILITY:
      return {
        ...state,
        visible2: !state.visible3,
      };
    case constants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        // isRestaurantloading: falseyValueCases(state.allRestaurant.length),
        isRestaurantloading: true,
      };
    case constants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        isRestaurantloading: false,
        allRestaurant: action.payload,
      };
    case constants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        isRestaurantloading: false,
      };
    case constants.GET_RESTAURANT_DETAILS_REQUEST:
      return {
        ...state,
        isByIdLoading: true,
        visible2: false,
      };
    case constants.GET_RESTAURANT_DETAILS_SUCCESS:
      return {
        ...state,
        isRestaurantByIdLoading: false,
        restaurantdeatils: action.payload,
        visible2: true,
      };
    case constants.GET_RESTAURANT_DETAILS_FAILURE:
      return {
        ...state,
        isRestaurantByIdLoading: false,
        visible2: true,
      };
    case constants.GET_RESTAURANT_BY_ID_REQUEST:
      return {
        ...state,
        isRestaurantByIdLoading: true,
      };
    case constants.GET_RESTAURANT_BY_ID_SUCCESS:
      return {
        ...state,
        isRestaurantByIdLoading: false,
        restaurant: action.payload,
      };
    case constants.GET_RESTAURANT_BY_ID_FAILURE:
      return {
        ...state,
        isRestaurantByIdLoading: false,
      };
    case constants.GET_RESTAURANT_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        isByIdLoading: true,
      };
    case constants.GET_RESTAURANT_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        isRESTAURANTByIdLoading: false,

        restaurantdeatils: action.payload,
      };
    case constants.GET_RESTAURANT_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        isRestaurantByIdLoading: false,
      };
    case constants.EMPTY_Restaurant_BY_ID_DATA:
      return {
        ...state,
        Restaurant: {},
      };
    case constants.UPDATE_RESTAURANT_REQUEST:
      return {
        ...state,
        isUpdateRestaurantLoading: true,
      };
    case constants.UPDATE_RESTAURANT_SUCCESS:
      return {
        ...state,
        isUpdateRestaurantLoading: false,
        restaurantdeatils: action.payload,
      };
    case constants.UPDATE_RESTAURANT_FAILURE:
      return {
        ...state,
        isUpdateRestaurantLoading: false,
        RestaurantError: action.payload?.slug,
      };
    case constants.CREATE_RESTAURANT_REQUEST:
      return {
        ...state,
        isCreateRestaurantLoading: true,
      };
    case constants.CREATE_RESTAURANT_SUCCESS:
      return {
        ...state,
        isCreateRestaurantLoading: false,
        restaurantdetailsId: action.payload,
      };
    case constants.CREATE_RESTAURANT_FAILURE:
      return {
        ...state,
        isCreateRestaurantLoading: false,
        RestaurantError: action.payload?.slug,
      };
    case constants.DELETE_RESTAURANT_REQUEST:
      return {
        ...state,
        isDeleteRestaurantLoading: true,
      };
    case constants.DELETE_RESTAURANT_SUCCESS:
      return {
        ...state,
        isDeleteRestaurantLoading: false,
      };
    case constants.DELETE_RESTAURANT_FAILURE:
      return {
        ...state,
        isDeleteRestaurantLoading: false,
      };
    case constants.UPDATE_RESTAURANT_DETAILS_REQUEST:
      return {
        ...state,
        isUpdateRestaurantLoading: true,
      };
    case constants.UPDATE_RESTAURANT_DETAILS_SUCCESS:
      return {
        ...state,
        isUpdateRestaurantLoading: false,
        restaurantdeatils: action.payload,
      };
    case constants.UPDATE_RESTAURANT_DETAILS_FAILURE:
      return {
        ...state,
        isUpdateRestaurantLoading: false,
      };
    case constants.GET_SELECTED_RESTAURANT_ID:
      return {
        ...state,
        setRestaurantId: action.payload,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    case constants.UPDATE_BRANDING_LOGO_REQUEST:
      return {
        ...state,
        isUpdateBrandingLogoLoading: true,
      };
    case constants.UPDATE_BRANDING_LOGO_SUCCESS:
      return {
        ...state,
        isUpdateBrandingLogoLoading: false,
        restaurant: action.payload,
      };
    case constants.UPDATE_BRANDING_LOGO_FAILURE:
      return {
        ...state,
        isUpdateBrandingLogoLoading: false,
      };
    default:
      return state;
  }
};
