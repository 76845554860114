import constants from '../Utils/constants';
const initialState = {
  isSupplierloading: false,
  allSupplier: [],
  isCreateSupplieroading: false,
  isDeleteSupplierLoading: false,
  isUpdateSupplierLoading: false,
  setSupplierId: '',
  Supplier: {},
  isSupplierByIdLoading: false,
};

export const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SUPPLIER_REQUEST:
      return {
        ...state,
        isSupplierloading: true,
      };
    case constants.GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        isSupplierloading: false,
        allSupplier: action.payload,
      };
    case constants.GET_SUPPLIER_FAILURE:
      return {
        ...state,
        isSupplierloading: false,
      };
    case constants.CREATE_SUPPLIER_REQUEST:
      return {
        ...state,
        isCreateSupplieroading: true,
      };
    case constants.CREATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        isCreateSupplieroading: false,
      };
    case constants.CREATE_SUPPLIER_FAILURE:
      return {
        ...state,
        isCreateSupplieroading: false,
      };
    case constants.DELETE_SUPPLIER_REQUEST:
      return {
        ...state,
        isDeleteSupplierLoading: true,
      };
    case constants.DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        isDeleteSupplierLoading: false,
      };
    case constants.DELETE_SUPPLIER_FAILURE:
      return {
        ...state,
        isDeleteSupplierLoading: false,
      };
    case constants.UPDATE_SUPPLIER_REQUEST:
      return {
        ...state,
        isUpdateSupplierLoading: true,
      };
    case constants.UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        isUpdateSupplierLoading: false,
      };
    case constants.UPDATE_SUPPLIER_FAILURE:
      return {
        ...state,
        isUpdateSupplierLoading: false,
      };
    case constants.GET_SUPPLIER_BY_ID_REQUEST:
      return {
        ...state,
        isSupplierByIdLoading: true,
      };
    case constants.GET_SUPPLIER_BY_ID_SUCCESS:
      return {
        ...state,
        isSupplierByIdLoading: false,
        Supplier: action.payload,
      };
    case constants.GET_SUPPLIER_BY_ID_FAILURE:
      return {
        ...state,
        isSupplierByIdLoading: false,
      };
    // case constants.GET_SELECTED_CATEGORY_ID:
    //   return {
    //     ...state,
    //     setSupplierId: action.payload,
    //   };
    default:
      return state;
  }
};
