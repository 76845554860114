import API from '../Utils/API';

export const getFeatures = () => (dispatch) => {
  dispatch({ type: 'GET_FEATURES_REQUEST' });
  API.get(`api/features`)
    .then((response) => {
      dispatch({ type: 'GET_FEATURES_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_FEATURES_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const createFeature =
  (data, successCreateFeature, failureCreateFeature) => (dispatch) => {
    dispatch({ type: 'CREATE_FEATURE_REQUEST' });
    API.post('api/features', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_FEATURE_SUCCESS',
          payload: response.data,
        });
        successCreateFeature && successCreateFeature();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_FEATURE_FAILURE',
          payload: error.response.data,
        });
        failureCreateFeature && failureCreateFeature(error.response.data);
      });
  };

export const updateFeature =
  (data, successUpdateCallBack, failureUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_FEATURE_REQUEST' });
    API.put(`api/features/${data.id}`, data)
      .then((response) => {
        dispatch({ type: 'UPDATE_FEATURE_SUCCESS', payload: response.data });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_FEATURE_FAILURE',
          payload: error.response.data,
        });
        failureUpdateCallBack && failureUpdateCallBack(error.response.data);
      });
  };

export const getFeatureById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_FEATURE_BY_ID_REQUEST' });
  API.get(`api/features/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_FEATURE_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_FEATURE_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
