import React, { useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useDispatch, useSelector } from 'react-redux';
import { FaStarOfLife } from 'react-icons/fa6';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router';
import {
  createWaiter,
  getWaiters,
  updateWaiter,
} from '../../Actions/WaitersAction';
import { waiterSchema } from '../../Utils/Schema';

const WaiterModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, restaurant, allWaiters } =
    props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isCreateWaiterLoading, isUpdateWaiterLoading } = useSelector(
    (state) => {
      const { isCreateWaiterLoading, isUpdateWaiterLoading } = state.waiter;
      return {
        isCreateWaiterLoading,
        isUpdateWaiterLoading,
      };
    }
  );
  const the_waiter = _.filter(allWaiters, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      first_name: the_waiter[0]?.first_name,
      last_name: the_waiter[0]?.last_name,
      waiter_code: the_waiter[0]?.waiter_code,
    },
    resolver: yupResolver(waiterSchema),
  });

  const { orgId } = useParams();

  const handleCloseWaiter = () => {
    setEditId('');
    setValue('first_name', '');
    setValue('last_name', '');
    setValue('waiter_code', '');
    setIsModalVisible(false);
  };
  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCancel = () => {
    setEditId('');
    setValue('first_name', '');
    setValue('last_name', '');
    setValue('waiter_code', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset();
  };
  const handleCreateWaiter = (data) => {
    dispatch(createWaiter(data, successCallbackWaiter, failureCallbackWaiter));
  };
  const successCallbackWaiter = () => {
    setIsModalVisible(false);
    dispatch(getWaiters());
    setEditId('');
    setTimeout(() => {
      message.success('Your Waiter Created Successfully');
    }, 1000);
  };
  const failureCallbackWaiter = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse?.waiter_code[0]);
    }, 1000);
  };

  const handleEditWaiter = (data) => {
    data.id = editId;
    dispatch(updateWaiter(data, UpdateCallBackWaiter, failureUpdateCallBack));
  };
  const UpdateCallBackWaiter = () => {
    reset();
    setIsModalVisible(false);
    setEditId('');
    dispatch(getWaiters());
    clearErrors();
    setTimeout(() => {
      message.success('Your Waiter Updated Successfully');
    }, 1000);
  };

  const failureUpdateCallBack = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse?.waiter_code[0]);
    }, 1000);
  };

  return (
    <Spin spinning={isCreateWaiterLoading || isUpdateWaiterLoading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurantDrawer()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={handleCloseWaiter}
            >
              Waiters
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update Waiter' : 'Add new Waiter'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {editId ? 'Update Waiter' : 'Add new Waiter'}
          </Typography>
        </Col>
      </Row>
      <form
        onSubmit={handleSubmit(editId ? handleEditWaiter : handleCreateWaiter)}
      >
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Typography
              className='formHeading'
              style={{ marginBottom: '-15px' }}
            >
              First Name
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
          </Col>
          <Col span={12}>
            <Controller
              name='first_name'
              control={control}
              className='inputLabel'
              as={<Input size='large' />}
            />
          </Col>
        </Row>
        {errors.first_name && (
          <p style={{ color: '#eb5757' }}>{errors.first_name.message}</p>
        )}
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Typography
              className='formHeading'
              style={{ marginBottom: '-15px' }}
            >
              Last name
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
          </Col>
          <Col span={12}>
            <Controller
              name='last_name'
              control={control}
              className='inputLabel'
              as={<Input size='large' />}
            />
          </Col>
        </Row>
        {errors.last_name && (
          <p style={{ color: '#eb5757' }}>{errors.last_name.message}</p>
        )}
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Typography
              className='formHeading'
              style={{ marginBottom: '-15px' }}
            >
              Code
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
          </Col>
          <Col span={12}>
            <Controller
              name='waiter_code'
              control={control}
              className='inputLabel'
              as={<Input size='large' />}
            />
          </Col>
        </Row>
        {errors.waiter_code && (
          <p style={{ color: '#eb5757' }}>{errors.waiter_code.message}</p>
        )}

        <Col span={12} style={{ marginTop: '40px' }}>
          <Row gutter={[16, 16]} className='button' justify='center'>
            <Col xl={6} lg={8} md={10} sm={12} xs={12}>
              {editId ? (
                <Button
                  className={isDirty ? 'cancelButton' : 'cancelButtonDirty'}
                  onClick={handleeditCancel}
                  size='large'
                  disabled={!isDirty}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  className='cancelButton'
                  onClick={handleCancel}
                  size='large'
                >
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={6} lg={8} md={10} sm={12} xs={12}>
              {editId ? (
                <Button
                  size='large'
                  className={isDirty ? 'detailsButton' : 'classButton'}
                  htmlType='submit'
                  disabled={!isDirty}
                >
                  Update
                </Button>
              ) : (
                <Button
                  size='large'
                  className='detailsButton'
                  htmlType='submit'
                >
                  Create
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </form>
    </Spin>
  );
};

export default WaiterModalForm;
