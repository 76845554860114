import React, { useState } from 'react';
import { Row, Col, Drawer, Typography, Spin } from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import { useSelector } from 'react-redux';

const FeaturesFlagDetails = (props) => {
  const { drawerVisible, setDrawerVisible } = props;
  const [width] = useState(window.innerWidth);
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { FeaturesFlag, isGetFeaturesFlagByIdLoading } = useSelector(
    (state) => {
      const { FeaturesFlag, isGetFeaturesFlagByIdLoading } = state.featuresFlag;
      return {
        FeaturesFlag,
        isGetFeaturesFlagByIdLoading,
      };
    }
  );

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={drawerVisible}
      width={width > 400 ? '400px' : '100%'}
    >
      {FeaturesFlag && (
        <Spin spinning={isGetFeaturesFlagByIdLoading}>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>
                Features flag name
              </Typography>
              <Typography className='detailsubheading'>
                {FeaturesFlag?.feature?.name}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Description</Typography>
              <Typography className='detailsubheading'>
                {FeaturesFlag?.feature?.description}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Active</Typography>
              <Typography className='detailsubheading'>
                {FeaturesFlag?.active ? 'Yes' : 'No'}
              </Typography>
            </Col>
          </Row>
        </Spin>
      )}
    </Drawer>
  );
};
export default FeaturesFlagDetails;
