import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Spin, Tooltip, Button, Breadcrumb } from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import { EditColumnAction } from '../CustomTable/CustomTableColumnAction';
import {
  getFeaturesFlag,
  getFeaturesFlagById,
} from '../../Actions/FeaturesFlagAction';
import FeaturesFlagModalForm from './FeaturesFlagModalForm';
import { getFeatures } from '../../Actions/FeaturesAction';
import FeaturesFlagDetails from './FeaturesFlagDetails';
import { useNavigate, useParams } from 'react-router';

const FeaturesFlag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { restaurant, allFeaturesFlag, isFeaturesFlagLoading } = useSelector(
    (state) => {
      const { allFeaturesFlag, isFeaturesFlagLoading } = state.featuresFlag;
      const { restaurant } = state.restaurant;
      return {
        restaurant,
        isFeaturesFlagLoading,
        allFeaturesFlag,
      };
    }
  );

  useEffect(() => {
    dispatch(getFeaturesFlag());
  }, [dispatch]);

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleOpen = (Id) => {
    dispatch(getFeaturesFlagById(Id));
    setDrawerVisible(true);
  };
  const handleAddNewFeature = () => {
    setEditId('');
    setIsModalVisible(true);
    dispatch(getFeatures());
  };

  const Columns = [
    {
      title: 'Features Flag Name',
      dataIndex: 'name',
      width: '12%',
      render: (Id, { id, feature }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${feature?.name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {feature?.name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '12%',
      render: (id, { feature }) => {
        return (
          <Row>
            <Col>
              <Typography>{feature?.description}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: '6%',
      render: (active) => {
        return (
          <Row>
            <Col>
              <Typography>{active ? 'Yes' : 'No'}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '2%',
      render: (id) => {
        return (
          <Row>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isFeaturesFlagLoading}>
      <Row gutter={[0, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <FeaturesFlagModalForm
              allFeaturesFlag={allFeaturesFlag}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
            ></FeaturesFlagModalForm>
          ) : (
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Features Flag
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Features Flag</Typography>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleAddNewFeature}
                    >
                      Add new Features Flag
                    </Button>
                  </Col>
                </Row>
              </Col>
              <FeaturesFlagDetails
                setDrawerVisible={setDrawerVisible}
                drawerVisible={drawerVisible}
              ></FeaturesFlagDetails>
              <Col span={24}>
                <CustomTable columns={Columns} data={allFeaturesFlag} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default FeaturesFlag;
