import React, { useState } from 'react';
import { Row, Col, Drawer, Typography, Spin } from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import { useSelector } from 'react-redux';

const FeatureDetails = (props) => {
  const { drawerVisible, setDrawerVisible } = props;
  const [width] = useState(window.innerWidth);
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { Feature, isGetFeatureByIdLoading } = useSelector((state) => {
    const { Feature, isGetFeatureByIdLoading } = state.feature;
    return {
      Feature,
      isGetFeatureByIdLoading,
    };
  });

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={drawerVisible}
      width={width > 400 ? '400px' : '100%'}
    >
      {Feature && (
        <Spin spinning={isGetFeatureByIdLoading}>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Feature name</Typography>
              <Typography className='detailsubheading'>
                {Feature?.name}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Description</Typography>
              <Typography className='detailsubheading'>
                {Feature?.description}
              </Typography>
            </Col>
          </Row>
        </Spin>
      )}
    </Drawer>
  );
};
export default FeatureDetails;
