import API from '../Utils/API';

export const getSupplier = () => (dispatch) => {
  dispatch({ type: 'GET_SUPPLIER_REQUEST' });
  API.get(`api/suppliers`)
    .then((response) => {
      dispatch({ type: 'GET_SUPPLIER_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_SUPPLIER_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const createSupplier =
  (data, successCreateSupplier, failureCreateSupplier) => (dispatch) => {
    dispatch({ type: 'CREATE_SUPPLIER_REQUEST' });
    API.post('api/suppliers', data)
      .then((response) => {
        dispatch({ type: 'CREATE_SUPPLIER_SUCCESS', payload: response.data });
        successCreateSupplier && successCreateSupplier();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_SUPPLIER_FAILURE',
          payload: error.response.data,
        });
        failureCreateSupplier && failureCreateSupplier();
      });
  };
export const deleteSupplier =
  (SupplierId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_SUPPLIER_REQUEST' });
    API.delete(`api/suppliers/${SupplierId}`)
      .then((response) => {
        dispatch({ type: 'DELETE_SUPPLIER_SUCCESS', payload: response.data });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_SUPPLIER_FAILURE',
          payload: error.response.data,
        });
      });
  };
export const updateSupplier = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_SUPPLIER_REQUEST' });
  API.put(`api/suppliers/${data.id}`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_SUPPLIER_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_SUPPLIER_FAILURE',
        payload: error.response.data,
      });
    });
};
