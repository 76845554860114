import { isEmpty } from 'lodash';
import constants from '../Utils/constants';
const initialState = {
  isLoginUserLoading: false,
  isVerifyOtpLoading: false,
  isCreateUserLoading: false,
  isAuthenticated: false,
  currentUser: null,
  isUpdateUserLoading: false,
  userById: {},
  isGetUserByIdLoading: false,
  isDeleteUserLoading: false,
  redirectPage: 1,
  loginOtp: '',
  phoneNumberwithCountryCode: {},
};
export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USERS_REQUEST:
      return {
        ...state,
        isGetUsersLoading: true,
      };
    case constants.GET_USERS_SUCCESS:
      return {
        ...state,
        isGetUsersLoading: false,
        currentUser: action.payload,
      };
    case constants.GET_USERS_FAILURE:
      return {
        ...state,
        isGetUsersLoading: false,
      };
    case constants.GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        isGetUsersLoading: true,
      };
    case constants.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        isGetUsersLoading: false,
        currentUser: action.payload,
      };
    case constants.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        isGetUsersLoading: false,
      };
    case constants.LOGIN_OTP:
      return {
        ...state,
        loginOtp: action.payload,
      };

    case constants.LOGIN_USER_REQUEST:
      return {
        ...state,
        isLoginUserLoading: true,
      };
    case constants.LOGIN_USER_SUCCESS:
      return {
        ...state,
        isLoginUserLoading: false,
      };
    case constants.LOGIN_USER_FAILURE:
      return {
        ...state,
        isLoginUserLoading: false,
      };
    case constants.VERIFY_OTP_REQUEST:
      return {
        ...state,
        isVerifyOtpLoading: true,
      };
    case constants.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isVerifyOtpLoading: false,
      };
    case constants.VERIFY_OTP_FAILURE:
      return {
        ...state,
        isVerifyOtpLoading: false,
      };
    case constants.SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumberwithCountryCode: action.payload,
      };

    case constants.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case constants.REMOVE_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    // case constants.GET_USER_BY_ID_REQUEST:
    //   return {
    //     ...state,
    //     userById: {},
    //     isGetUserByIdLoading: true,
    //   };
    // case constants.GET_USER_BY_ID_SUCCESS:
    //   return {
    //     ...state,
    //     isGetUserByIdLoading: false,
    //     userById: action.payload,
    //   };
    // case constants.GET_USER_BY_ID_FAILURE:
    //   return {
    //     ...state,
    //     isGetUserByIdLoading: false,
    //   };

    case constants.SET_REDIRECT_PAGE:
      return {
        ...state,
        redirectPage: action.payload,
      };

    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
