import API from '../Utils/API';

export const getAllUsers = (params) => (dispatch) => {
  dispatch({ type: 'GET_ALL_USERS_REQUEST' });
  API.get(`api/all_users${params}`)
    .then((response) => {
      dispatch({ type: 'GET_ALL_USERS_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_ALL_USERS_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
