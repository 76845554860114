import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Spin, Tooltip, Button } from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import { EditColumnAction } from '../CustomTable/CustomTableColumnAction';
import { getFeatureById, getFeatures } from '../../Actions/FeaturesAction';
import FeatureModalForm from './FeatureModalForm';
import FeatureDetails from './FeatureDetails';
import moment from 'moment';

const Features = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { allFeatures, isFeatureLoading } = useSelector((state) => {
    const { allFeatures, isFeatureLoading } = state.feature;
    return {
      isFeatureLoading,
      allFeatures,
    };
  });

  useEffect(() => {
    dispatch(getFeatures());
  }, [dispatch]);

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleOpen = (Id) => {
    dispatch(getFeatureById(Id));
    setDrawerVisible(true);
  };
  const handleAddNewFeature = () => {
    setIsModalVisible(true);
  };
  const Columns = [
    {
      title: 'Feature Name',
      dataIndex: 'name',
      width: '12%',
      render: (name, { id }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '12%',
      render: (description) => {
        return (
          <Row>
            <Col>
              <Typography>{description}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      width: '6%',
      render: (created_at) => {
        return (
          <Row>
            <Col style={{ cursor: 'pointer' }}>
              <Tooltip title={created_at}>
                <Typography>
                  {moment(created_at).tz('Asia/Kolkata').format('DD-MM-YYYY')}
                </Typography>
                <Typography>
                  {moment(created_at).tz('Asia/Kolkata').format('HH:mm')}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      width: '6%',
      render: (updated_at) => {
        return (
          <Row>
            <Col style={{ cursor: 'pointer' }}>
              <Tooltip title={updated_at}>
                <Typography>
                  {moment(updated_at).tz('Asia/Kolkata').format('DD-MM-YYYY')}
                </Typography>
                <Typography>
                  {moment(updated_at).tz('Asia/Kolkata').format('HH:mm')}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '2%',
      render: (id) => {
        return (
          <Row>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isFeatureLoading}>
      <Row gutter={[0, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <FeatureModalForm
              allFeatures={allFeatures}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
            ></FeatureModalForm>
          ) : (
            <Row gutter={[0, 24]}>
              <Col span={18}>
                <Typography className='heading'>Features</Typography>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleAddNewFeature}
                    >
                      Add new Feature
                    </Button>
                  </Col>
                </Row>
              </Col>
              <FeatureDetails
                setDrawerVisible={setDrawerVisible}
                drawerVisible={drawerVisible}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                setEditId={setEditId}
                editId={editId}
              ></FeatureDetails>
              <Col span={24}>
                <CustomTable columns={Columns} data={allFeatures} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Features;
