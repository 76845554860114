import React, { useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useDispatch, useSelector } from 'react-redux';
import { FaStarOfLife } from 'react-icons/fa6';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router';
import { languagesSchema } from '../../Utils/Schema';
import {
  createLanguage,
  getLanguages,
  updateLanguage,
} from '../../Actions/LanguagesAction';

const LanguageModalForm = (props) => {
  const [failureValue, setFailureValue] = useState('');
  const { editId, setEditId, setIsModalVisible, restaurant, allLanguages } =
    props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isCreateLanguageLoading, isUpdateLanguageLoading } = useSelector(
    (state) => {
      const { isCreateLanguageLoading, isUpdateLanguageLoading } =
        state.languages;
      return {
        isCreateLanguageLoading,
        isUpdateLanguageLoading,
      };
    }
  );
  const the_language = _.filter(allLanguages, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: the_language[0]?.name,
      code: the_language[0]?.code,
    },
    resolver: yupResolver(languagesSchema),
  });

  const { orgId } = useParams();

  const handleCloseLanguages = () => {
    setValue('name', '');
    setValue('code', '');
    setIsModalVisible(false);
  };
  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCancel = () => {
    setEditId('');
    setValue('name', '');
    setValue('code', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset();
  };
  const handleCreateLanguage = (data) => {
    dispatch(
      createLanguage(data, successCallbackLanguage, failureCallbackLanguage)
    );
  };
  const successCallbackLanguage = () => {
    setIsModalVisible(false);
    dispatch(getLanguages());
    setEditId('');
    setTimeout(() => {
      message.success('Your Layout Created Successfully');
    }, 1000);
  };
  const failureCallbackLanguage = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const handleEditLanguage = (data) => {
    data.id = editId;
    dispatch(
      updateLanguage(data, UpdateCallBackLanguage, failureUpdateCallBack)
    );
  };
  const UpdateCallBackLanguage = () => {
    reset();
    setIsModalVisible(false);
    setEditId('');
    dispatch(getLanguages());
    clearErrors();
    setTimeout(() => {
      message.success('Your Language Updated Successfully');
    }, 1000);
  };

  const failureUpdateCallBack = (failureResponse) => {
    setFailureValue(failureResponse.name);
  };

  return (
    <Spin spinning={isCreateLanguageLoading || isUpdateLanguageLoading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurantDrawer()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={handleCloseLanguages}
            >
              Languages
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update Language' : 'Add new Language'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {editId ? 'Update Language' : 'Add new Language'}
          </Typography>
        </Col>
      </Row>
      <form
        onSubmit={handleSubmit(
          editId ? handleEditLanguage : handleCreateLanguage
        )}
      >
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Typography
              className='formHeading'
              style={{ marginBottom: '-15px' }}
            >
              Name
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
          </Col>
          <Col span={12}>
            <Controller
              name='name'
              control={control}
              className='inputLabel'
              as={<Input size='large' />}
            />
          </Col>
        </Row>
        {errors.name && (
          <p style={{ color: '#eb5757' }}>{errors.name.message}</p>
        )}
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Typography
              className='formHeading'
              style={{ marginBottom: '-15px' }}
            >
              Code
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
          </Col>
          <Col span={12}>
            <Controller
              name='code'
              control={control}
              className='inputLabel'
              as={<Input size='large' />}
            />
          </Col>
        </Row>
        {errors.code && (
          <p style={{ color: '#eb5757' }}>{errors.code.message}</p>
        )}

        <Col span={12} style={{ marginTop: '40px' }}>
          <Row gutter={[16, 16]} className='button' justify='center'>
            <Col xl={6} lg={4} md={8} sm={12} xs={12}>
              {editId ? (
                <Button
                  className={isDirty ? 'cancelButton' : 'cancelButtonDirty'}
                  onClick={handleeditCancel}
                  size='large'
                  disabled={!isDirty}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  className='cancelButton'
                  onClick={handleCancel}
                  size='large'
                >
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={6} lg={4} md={8} sm={12} xs={12}>
              {editId ? (
                <Button
                  size='large'
                  className={isDirty ? 'detailsButton' : 'classButton'}
                  htmlType='submit'
                  disabled={!isDirty}
                >
                  Update
                </Button>
              ) : (
                <Button
                  size='large'
                  className='detailsButton'
                  htmlType='submit'
                >
                  Create
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </form>
    </Spin>
  );
};

export default LanguageModalForm;
