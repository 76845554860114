import React, { useState, useRef, useEffect } from 'react';
import { Typography } from 'antd';
import { IoMdCalendar } from 'react-icons/io';
import { FaCaretDown } from 'react-icons/fa';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DateRangeSelector = ({ selectionRange, onChange, type }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });
  const rangeRef = useRef(null);
  const pickerRef = useRef(null);

  const togglePicker = () => {
    if (!isPickerOpen) {
      const rect = rangeRef.current.getBoundingClientRect();
      setPickerPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
    setIsPickerOpen(!isPickerOpen);
  };

  const handleClickOutside = (event) => {
    if (
      rangeRef.current &&
      !rangeRef.current.contains(event.target) &&
      pickerRef.current &&
      !pickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formatDate = (date) => {
    return date?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  return (
    <div style={{ position: 'relative', marginBottom: '7px' }}>
      <Typography
        ref={rangeRef}
        onClick={togglePicker}
        style={{
          position: 'relative',
          cursor: 'pointer',
          display: 'inline-block',
          border: '1px solid #7b7b7b',
          borderRadius: '4px',
          padding: '8px 33px',
          fontFamily: 'Circular-400',
          wordSpacing: '-5px',
        }}
      >
        <IoMdCalendar
          style={{
            position: 'absolute',
            left: '6px',
            fontSize: '20px',
          }}
        />
        {`${formatDate(selectionRange?.startDate)} - ${formatDate(
          selectionRange?.endDate
        )}`}
        <FaCaretDown
          style={{
            position: 'absolute',
            marginLeft: '7px',
            fontSize: '18px',
          }}
        />
      </Typography>
      {isPickerOpen && (
        <div
          ref={pickerRef}
          style={{
            position: 'absolute',
            top: '100%',
            left: type === 'orders' ? '0%' : '-128%',
            zIndex: 200,
          }}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={onChange}
            rangeColors={['#3a3a3a']}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangeSelector;
