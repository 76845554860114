import React, { useState } from 'react';
import { Row, Col, Drawer, Typography, Spin } from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import { useSelector } from 'react-redux';

const LanguageDetails = (props) => {
  const { drawerVisible, setDrawerVisible } = props;
  const [width] = useState(window.innerWidth);
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { Language, isGetLanguageByIdLoading } = useSelector((state) => {
    const { Language, isGetLanguageByIdLoading } = state.languages;
    return {
      Language,
      isGetLanguageByIdLoading,
    };
  });

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={drawerVisible}
      width={width > 400 ? '400px' : '100%'}
    >
      {Language && (
        <Spin spinning={isGetLanguageByIdLoading}>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Language name</Typography>
              <Typography className='detailsubheading'>
                {Language?.name}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Code</Typography>
              <Typography className='detailsubheading'>
                {Language?.code}
              </Typography>
            </Col>
          </Row>
        </Spin>
      )}
    </Drawer>
  );
};
export default LanguageDetails;
