import constants from '../Utils/constants';
const initialState = {
  isLanguagesLoading: false,
  isCreateLanguageLoading: false,
  isGetLanguageByIdLoading: false,
  isUpdateLanguageLoading: false,
  isDeleteLanguageLoading: false,
  allLanguages: [],
  Language: {},
};

export const languagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_LANGUAGES_REQUEST:
      return {
        ...state,
        isLanguagesLoading: true,
      };
    case constants.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        isLanguagesLoading: false,
        allLanguages: action.payload,
      };
    case constants.GET_LANGUAGES_FAILURE:
      return {
        ...state,
        isLanguagesLoading: false,
      };
    case constants.CREATE_LANGUAGE_REQUEST:
      return {
        ...state,
        isCreateLanguageLoading: true,
      };
    case constants.CREATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        isCreateLanguageLoading: false,
      };
    case constants.CREATE_LANGUAGE_FAILURE:
      return {
        ...state,
        isCreateLanguageLoading: false,
      };
    case constants.UPDATE_LANGUAGE_REQUEST:
      return {
        ...state,
        isUpdateLanguageLoading: true,
      };
    case constants.UPDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        isUpdateLanguageLoading: false,
      };
    case constants.UPDATE_LANGUAGE_FAILURE:
      return {
        ...state,
        isUpdateLanguageLoading: false,
      };
    case constants.DELETE_LANGUAGE_REQUEST:
      return {
        ...state,
        isDeleteLanguageLoading: true,
      };
    case constants.DELETE_LANGUAGE_SUCCESS:
      return {
        ...state,
        isDeleteLanguageLoading: false,
      };
    case constants.DELETE_LANGUAGE_FAILURE:
      return {
        ...state,
        isDeleteLanguageLoading: false,
      };
    case constants.GET_LANGUAGE_BY_ID_REQUEST:
      return {
        ...state,
        isGetLanguageByIdLoading: true,
      };
    case constants.GET_LANGUAGE_BY_ID_SUCCESS:
      return {
        ...state,
        isGetLanguageByIdLoading: false,
        Language: action.payload,
      };
    case constants.GET_LANGUAGE_BY_ID_FAILURE:
      return {
        ...state,
        isGetLanguageByIdLoading: false,
      };
    default:
      return state;
  }
};
